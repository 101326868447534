<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="fullname"
                  outlined
                  label="Fullname"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="email"
                  outlined
                  label="Email"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="phone"
                  outlined
                  label="Phone"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="password"
                  label="Password"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  @keyup.enter.stop="validate"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  :items="statuses"
                  label="Status"
                  v-model="status"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="roles"
                  v-model="role"
                  label="Roles"
                  outlined
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Add {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
          <v-snackbar v-model="snackbar" v-if="allRegister.data != undefined">
            {{ allRegister.data.message }}
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddUser",
  components: { PageHeader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    adspositions: ["top", "bottom", "left", "right"],
    headercontent: {
      title: "Add User",
      addlink: "/dashboard/user/add",
      downloadlink: true,
    },
    fullname: "",
    showpassword: false,
    checkbox: false,
    snackbar: false,
    phone: "",
    email: "",
    password: "",
    emailRules: [(v) => !!v || "Email is required"],
    phoneRule: [(v) => !!v || "Phone Number is required"],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    fullnameRule: [(v) => !!v || "Fullname is required"],
    checkboxRule: [(v) => !!v || "You need to accept first"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    roles: [
      { name: "Admin", value: "admin" },
      { name: "Job Seeker", value: "employee" },
    ],
    role: "admin",
    status: true,
  }),
  methods: {
    ...mapActions(["registerByAdmin"]),
    validate() {
      if (this.$refs.form.validate()) {
        let userData = {
          fullname: this.fullname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          role: this.role,
        };
        this.registerByAdmin(userData);
        this.snackbar = true;
      }
    },
  },

  computed: {
    ...mapGetters(["allLoadingCurd", "allRegister"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.params.slug != undefined) {
        this.title = this.allCategory.title;
        this.description = this.allCategory.description;
        this.sort = this.allCategory.sort;
        this.status = this.allCategory.status;
        this.imageUrl = this.allCategory.imageUrl;
        this.id = this.allCategory._id;
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
